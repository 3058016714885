import * as Sentry from '@sentry/remix';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import prepareMsw from './utils/testing/prepareMsw.client';
import clientEnv from './env.client';

Sentry.init({
  dsn: 'https://a1355692e0cab2ba43032957ecdec8af@o4505332962820096.ingest.us.sentry.io/4507914971643904',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
  environment: clientEnv.VITE_APP_ENVIRONMENT,
});

prepareMsw().then(() =>
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  }),
);
